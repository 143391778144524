import Cookies from "js-cookie";
import { createContext, useEffect, useState, useContext } from "react";
import { TOKEN_NAME } from "../config";
import { validateToken } from "../services/auth.services";
import { getSearchParams } from "../utils/SearchParams";

interface AuthInterface {
    loading: boolean
}

const AuthContext = createContext<AuthInterface>(
    {
        loading: true
    }
);

interface Props {
    children: JSX.Element | JSX.Element[]
}

const AuthContextProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(true);
    const  params  = getSearchParams(window.location.href)
    const redirect = params.continue || ""

    useEffect(() => {
        setLoading(true)

        const token = Cookies.get(TOKEN_NAME)

        if(window.location.href.includes("/me")){
            if(!token) window.location.href = "/login"
            setLoading(false)
            return
        }

        if(!token) {
            console.log(redirect)
            setLoading(false)
            return
        }
        
        validateToken()
            .then( (data) => {
                if(!data) {
                    setLoading(false)
                    return
                }
                redirect  
                    ? window.location.href = decodeURIComponent(redirect)+"?ticket="+data.ticket
                    : window.location.href = "/me"
            } )
            .catch( () => setLoading(false) )

    }, []);

    return (
        <AuthContext.Provider value={
            {
                loading
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}


export { AuthContextProvider };

const ContextAuth = () => {
    return useContext(AuthContext);
}

export default ContextAuth;