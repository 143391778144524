import axios from "axios";
import { SERVICE_URL } from "../config";


export const getCountrys = () => {
    return axios.get(`${SERVICE_URL}api/countries`);
}

export const getTypesDocuments = () => {
    return axios.get(`${SERVICE_URL}api/document-types`);
}

export const getGenders = async () =>{
    return await axios.get(`${SERVICE_URL}api/genders`)
}