export function getCountryCodeFromPhoneNumber(phoneNumber: string) {
    const countryCodeRegex = /^\+(?:[0-9]){1,4}/; // Buscar el patrón de código de país
    const matches = phoneNumber.match(countryCodeRegex);

    if (matches && matches.length > 0) {
        const countryCode = matches[0];
        return countryCode;
    } else {
        console.error('No se pudo encontrar el código de país en el número de teléfono.');
        return "";
    }
}