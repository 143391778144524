import LogoPz from "../../../assets/img/logo-pozicion.png"

interface props {
    style?: string
    width : number 
    height : number 
}

const LogoPozicion = ({style, width, height}:props) => {
    return(
        <img src={LogoPz} alt="pozicion" className={style} width={width} height={height} />
    )
}

export default LogoPozicion