import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_COLOR_HOVER } from "../../styled-components/variables";

export const StyledMe = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledBoxMe = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    .title{
        font-size: 20px;
        font-weight: bold;
        span{
            color: ${PRIMARY_COLOR};
        }
    }
    .btn-primary {
        width: 120px;
        padding: 10px 30px;
        font-size: 16px;
        color: white;
        background-color: ${PRIMARY_COLOR};
        font-weight: bold;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    .btn-primary:hover {
        background-color: ${PRIMARY_COLOR_HOVER};
    }
`