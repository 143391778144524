import { IDocumentTypes } from "../../../interfaces/Global";
import { getTypesDocuments } from "../../../services/Global.services";
import React, { useEffect, useState } from "react";

interface props {
    name: string
    style: string
    handleChange?: (data:IDocumentTypes) => void
    value: string
}

const SelectTypeDocs = ({style, name, handleChange, value}:props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IDocumentTypes[]>([]);

    useEffect(() => {
        setLoading(true)
        getTypesDocuments()
            .then( (resp)=> {
                if(resp.data.code === 200){
                    setData(resp.data.data)
                }
            } )
            .catch( (error) => console.error(error))
            .finally( () => setLoading(false) )
    }, []);

    const handleData = (e: React.ChangeEvent<HTMLSelectElement>) =>{
        let resp = data.findIndex(item => item.id.toString() === e.currentTarget.value)
        if(handleChange) handleChange(data[resp]);
    }

    if(loading){
        return(
            <select className={style} name={name} value={value}>
                <option value="0" selected disabled hidden>Cargando</option>
            </select>
        )
    }

    return(
        <select className={style} name={name} value={value} onChange={handleData}>
            <option value="0" selected disabled hidden>Tipo de documento</option>
            {
                data.length > 0 && data.map( (item) => <option value={item.id}> {item.name} </option> )
            }
        </select>
    )
}

export default SelectTypeDocs