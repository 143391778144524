import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/Login";
import PageMe from "../pages/Me";
import Register from "../pages/Register";


const AuthRouter = () => {
    const routes = [
      {
        path: "/login",
        element: <Login/>,
      },
      {
        path: "/register",
        element: <Register/>,
      },
      {
        path: "/me",
        element: <PageMe/>,
      },
      {
          path: "*",
          element: <Navigate to="/login" />,
      },
    ];
  
    return useRoutes(routes);
  }
  
  export default AuthRouter;