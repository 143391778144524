import { QueryClient, QueryClientProvider } from "react-query";
import './App.css';
import { AuthContextProvider } from "./contexts/Auth.context";
import RootRouter from "./router/root.router";

const queryClient = new QueryClient()

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <RootRouter/>
        </div>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
