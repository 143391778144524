import LogoPozicion from "../../components/Logos/LogoPozicion"
import LinkContainer from "../../containers/Link.container";
import FormLogin from "./components/FormLogin";
import SVGFacebook from "../../components/svg/SVGFacebook";
import SVGGoogle from "../../components/svg/SVGGoogle";
import { StyledMainLogin } from "./styles";
import { useSearchParams } from "react-router-dom";

const Login = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const redirect = searchParams.get("continue")

    return (
        <StyledMainLogin>
            <div className="login-container">
                <LinkContainer href={redirect ? decodeURIComponent(redirect) : "/"}><LogoPozicion width={277} height={50} /></LinkContainer>
                <p className="login-title">Ingresa con</p>
                <p className="login-subtitle">tus redes sociales</p>
                <ul className="login-features">
                    <li className="login-item">
                        <SVGFacebook width={18} height={18} />
                    </li>
                    <li className="login-item">
                        <SVGGoogle width={20} height={20} />
                    </li>
                </ul>
                <p className="login-subtitle">tu usuario</p>
                <FormLogin />
                <p className="login-spam">
                    ¿Aún no tienes una cuenta?
                    &nbsp;
                    <LinkContainer href={redirect ? `/register?continue=${encodeURIComponent(redirect)}` : "/register"}>
                        <span>Regístrate</span>
                    </LinkContainer>
                </p>
            </div>
        </StyledMainLogin>
    )
}

export default Login