import { useEffect, useState } from "react";
import Avatar from "react-avatar"
import { Auth } from "../../interfaces/Global";
import { getDataUser, LoginOut } from "../../services/auth.services";
import { StyledMe,StyledBoxMe } from "./styles"
import MainLoader from "../../components/Loaders/MainLoader"
import LogoPozicion from "../../components/Logos/LogoPozicion";
import Cookies from "js-cookie";
import { TOKEN_NAME } from "../../config";

const PageMe = () => {
    const [loading, setLoading] = useState(false);
    const [dataUser, setDataUser] = useState<Auth | null>(null);

    const Logout = () => {
        setLoading(true)
        LoginOut()
        .then( (resp) => console.error(resp) )
        .catch( (error) => console.error(error) )
        .finally( () =>{ 
            Cookies.remove(TOKEN_NAME)
            window.location.href = `/`
        });
    }


    useEffect(() => {
        setLoading(true)
        getDataUser()
        .then((resp) => {
            if (resp.status !== 200) throw new Error("No auth")
            setDataUser({...resp.data.data})
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => setLoading(false))
    }, []);
    
    if(loading){
        return (
            <MainLoader/>
        )
    }

    if(!dataUser){
        return (
            <StyledMe>
                <p>Ocurrio un error</p>
            </StyledMe>
        )
    }

    return(
        <StyledMe>
            <StyledBoxMe>
                <a href="https://pozicion.com/">
                    <LogoPozicion width={200} height={40} />
                </a>
                <br />
                <Avatar src={dataUser.image || dataUser.full_name} name={dataUser.full_name} size="128" round={true} />
                <p className="title" >Hola, <span> {dataUser.full_name} </span> </p>
                <p> {dataUser.email} </p>
                <p> {dataUser.phone} </p>
                <p> {dataUser.country} </p>
                <br />
                <button onClick={Logout} className="btn-primary">Logout</button>
            </StyledBoxMe>
        </StyledMe>
    )
}

export default PageMe