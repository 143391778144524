import InputPassword from "../../../components/InputPassword";
import SelectCountrys from "./SelectCountrys";
import SimpleLoader from "../../../components/Loaders/SimpleLoader";
import { IFormRegister } from "../../../interfaces/Credentials";
import { RegisterUser } from "../../../services/auth.services";
import { getResponseByBack, ValidateCredentialsRegister } from "../../../utils/Validate";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from "react";
import SelectTypeDocs from "./SelectTypeDocs";
import SelectGender from "./SelectGender";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { getCountryCodeFromPhoneNumber } from "../../../utils/phone";

const FormRegister = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams]  = useSearchParams()
    const redirect = searchParams.get("continue")
    const [form, setForm] = useState<IFormRegister>({
        email: "",
        password: "",
        name: "",
        last_name: "",
        country: 0,
        phone: "",
        codePhone: "",
        document_type: null,
        document_number: 0,
        terms_and_conditions: false,
        birth_date: "",
        gender: null
    });
    const navigate = useNavigate()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.checked});
    }

    const handleSelect = (id:number, code:string) => {
        setForm({...form, country: id, codePhone: code});
    }

    const sendForm = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        const validate = ValidateCredentialsRegister(form);

        if(!validate.error){
            setLoading(true)
            RegisterUser({
                ...form,
                document_type: form.document_type ? form.document_type.id : 0,
                gender: form.gender ? form.gender.id : 0,
            })
            .then( (resp) => {
                if(resp.data.code === 400) setMessage("Los campos son incorrectos")
                
                if(resp.data.code === 201){
                    navigate(redirect ? `/login?continue=${encodeURIComponent(redirect)}` : "/login")
                }
            })
            .catch( (error) => {
                if(error.response && error.response.data){
                    let err = error.response.data.errors ? getResponseByBack(error.response.data.errors) : "Error al crear la cuenta"
                    setMessage(err)
                }
            } )
            .finally( () => setLoading(false))
        }else{
            setMessage(validate.message)
        }
    } 

    if(loading){
        return(
            <div  className="login-form">
                <SimpleLoader/>
                <p className="login-title">Creando cuenta</p>
            </div>
        )
    }

    return(
        <form className="login-form">
            <p className="txt-input">Nombres <span>*</span></p>
            <input 
                name="name"
                onChange={handleChange}
                className="login-input" 
                type="text" 
               value={form.name}
            />
            <p className="txt-input">Apellidos <span>*</span></p>
            <input 
                name="last_name"
                onChange={handleChange}
                className="login-input" 
                type="text" 
                value={form.last_name}
            />
            <p className="txt-input">Email <span>*</span></p>
            <input 
                name="email"
                onChange={handleChange}
                className="login-input" 
                type="email" 
                value={form.email}
            />
            <p className="txt-input">Fecha de Nacimiento <span>*</span></p>
            <input 
                className="login-input"  type="date" 
                placeholder="Fecha de Nacimiento"
                name="birth_date"
                onChange={(e) => setForm({...form, "birth_date": e.currentTarget.value})}  
                value={form.birth_date} 
            />
            <p className="txt-input">Genero <span>*</span></p>
            <SelectGender
                style="login-select" 
                name="gender" 
                value={form.gender ? form.gender.id.toString() : "0"}
                handleChange={(data) => setForm({...form, gender: data})}
            />
            <p className="txt-input">Tipo de documento <span>*</span></p>
            <SelectTypeDocs
                style="login-select" 
                name="document_type" 
                value={form.document_type ? form.document_type.id.toString() : "0"}
                handleChange={(data) => setForm({...form, document_type: data})}
            />
            <input 
                name="document_number"
                onChange={handleChange}
                disabled={!form.document_type}
                className="login-input" 
                type="number" 
                placeholder="Numero de documento:" 
                max={form.document_type ? form.document_type.characters : 8}
                value={form.document_number || "" }
            />
            {/*            <p className="txt-input">Pais</p>
            <SelectCountrys
                style="login-select" 
                name="country" 
                value={form.country.toString()+"#"+form.codePhone}
                handleChange={handleSelect}
    />*/}
            <p className="txt-input">Telefono <span>*</span></p>
            <PhoneInput
                className="login-input"
                defaultCountry="pe"
                value={form.phone}
                onChange={(phone, country) => setForm({...form, phone: phone, codePhone: getCountryCodeFromPhoneNumber(phone) })}
            />
            <p className="txt-input">Contraseña <span>*</span></p>
            <InputPassword
                name="password"
                handleChange={handleChange}
                style="login-input" 
                placeholder="" 
                value={form.password}
            />
            <div className="register-terms">
                <input name="terms_and_conditions" type="checkbox" checked={form.terms_and_conditions} onChange={handleCheck} />
                <p>Acepto los Términos y Condiciones y Política de Privacidad</p>
            </div>
            <p className="text-error"> { message } </p>
            <button onClick={sendForm} className="btn-primary login-btn">Registrar</button>
        </form>
    )
}

export default FormRegister