import styled from "styled-components";
import { PRIMARY_COLOR, PRIMARY_COLOR_HOVER, PRIMARY_FONT, SECONDARY_FONT, TEXT_COLOR } from "../../styled-components/variables";

export const StyledMainLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .login-container {
    padding: 1rem;
    text-align: center;
    width: 100%;
    max-width: 420px;
    border-radius: 5px;
  }
  .login-title {
    font-size: 20px;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
    font-family: ${SECONDARY_FONT};
    font-style: normal;
    margin: 5px 0;
  }
  .login-subtitle {
    font-size: 16px;
    color: ${TEXT_COLOR};
    margin: 5px 0;
  }
  .login-features {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    list-style: none;
  }
  .login-item {
    cursor: pointer;
    width: 48%;
    max-width: 200px;
    height: 40px;
    border: 0.5px solid #c4c4c4;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-item:hover {
    background-color: rgba(232, 232, 232, 0.307);
  }
  .login-form {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .login-input {
    width: 100%;
    max-width: 420px;
    height: 50px;
    background-color: rgba(232, 232, 232, 0.307);
    font-family: ${PRIMARY_FONT};
    outline: none;
    border: none;
    border-radius: 5px;
    margin: 0.5rem 0;
    padding: 0.5rem;
  }
  .login-select {
    width: 100%;
    max-width: 420px;
    height: 50px;
    background-color: rgba(232, 232, 232, 0.307);
    outline: none;
    border: none;
    border-radius: 5px;
    margin: 0.5rem 0;
    padding: 0.5rem;
  }

  .btn-primary {
    width: 100%;
    padding: 10px 30px;
    font-size: 16px;
    color: white;
    background-color: ${PRIMARY_COLOR};
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-primary:hover {
    background-color: ${PRIMARY_COLOR_HOVER};
  }

  .login-btn {
    max-width: 128px;
    height: 41px;
  }
  .login-spam {
    font-size: 16px;
    color: ${TEXT_COLOR};
  }
  .login-spam span {
    color: ${PRIMARY_COLOR};
  }
  .login-spam span:hover {
    color: ${PRIMARY_COLOR_HOVER};
  }
  .register-container {
    width: 100%;
    max-width: 700px;
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .react-international-phone-input-container{
      padding: 0.5rem;
      height: 100%;
    }
    .react-international-phone-country-selector{
      background-color: transparent;
      border: none;
    }
    .react-international-phone-country-selector-button{
      background-color: transparent;
      padding: 0.5rem;
      border: none;
    }
    .react-international-phone-input{
      width: 100%;
      background-color: transparent;
      padding: 0.5rem;
      border: none;
      font-size: 16px
    }
  }
  .register-container .subtitle {
    font-size: 14px;
    font-family: ${SECONDARY_FONT};
  }
  .register-terms {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 0;
  }
  .register-terms p {
    margin: 0;
    padding-left: 5px;
    text-align: start;
  }
  .container-input-password {
    width: 100%;
    position: relative;
  }
  .container-input-password input {
    padding-right: 10px;
  }
  .container-input-password .password-visible {
    position: absolute;
    top: 25px;
    right: 10px;
    cursor: pointer;
  }
  .txt-input{
    width: 100%;
    font-weight: 500;
    margin: 5px 0;
    text-align: start;
    font-size: 14px;
    span{
      color: firebrick;
    }
  }
  .text-error {
    color: firebrick;
    font-size: 10px;
    margin: 5px 0;
  }
`;
