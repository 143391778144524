import { NavLink } from "react-router-dom"

interface Props{
    children: JSX.Element | JSX.Element[]
    href: string
}

const LinkContainer = ({children, href}:Props) => {
    return(
        <NavLink to={href}>
            <a>
                {children}
            </a>
        </NavLink>
    )
}

export default LinkContainer