import { BrowserRouter as Router } from "react-router-dom";
import MainLoader from "../components/Loaders/MainLoader";
import ContextAuth from "../contexts/Auth.context";
import AuthRouter from "./auth.router";

const RootRouter = () => {
    const { loading } = ContextAuth();

    if(loading) {
        return(
            <MainLoader/>
        )
    }

    return (
        <Router basename="/">
            <AuthRouter />
        </Router>
    );
}

export default RootRouter