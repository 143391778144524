import { FormCredentials, IFormRegister } from "../interfaces/Credentials";

const REGEX_EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const REGEX_PASS = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})")

export const ValidateCredentials = (form: FormCredentials) => {
    let error = {
        message: "",
        error: false
    }

    if(form.email){
        if(!REGEX_EMAIL.test(form.email)){
            error.message = "Email invalido";
            error.error = true;
            return error;
        }
    }else{
        error.message = "Email invalido";
        error.error = true;
        return error;
    }

    if(!form.password){
        error.message = "Contraseña invalida";
        error.error = true;
        return error;
    }

    return error;

}
export const ValidateCredentialsRegister = (form: IFormRegister) => {
    let error = {
        message: "",
        error: false
    }

    if(form.email){
        if(!REGEX_EMAIL.test(form.email)){
            error.message = "Email invalido";
            error.error = true;
            return error;
        }
    }else{
        error.message = "Email invalido";
        error.error = true;
        return error;
    }

    if(!form.birth_date){
        error.message = "Selecciona tu fecha de nacimiento";
        error.error = true;
        return error;
    }

    if(!form.gender){
        error.message = "Selecciona tu genero";
        error.error = true;
        return error;
    }

    if(!form.document_type){
        error.message = "Selecciona tu tipo de documento";
        error.error = true;
        return error;
    }

    if(!form.document_number){
        error.message = "Selecciona tu numero de documento";
        error.error = true;
        return error;
    }

    if(form.document_number.toString().length !== form.document_type.characters){
        error.message = `El numero de documento tiene que tener ${form.document_type.characters} caracteres`;
        error.error = true;
        return error;
    }

    if(!form.password){
        error.message = "Contraseña invalida";
        error.error = true;
        return error;
    }

    if(form.password && !REGEX_PASS.test(form.password)){
        error.message = "Contraseña invalida";
        error.error = true;
        return error;
    }

    if(!form.name || !form.last_name  || !form.phone ){
        error.message = "Faltan llenar campos";
        error.error = true;
        return error;
    }

    if(!form.terms_and_conditions){
        error.message = "Tiene que aceptar los terminos para crear su cuenta";
        error.error = true;
        return error;
    }

    return error;

}

interface ErrorResponseRegister {
    email: string []
    password: string []
    document_number: string []
}

export const getResponseByBack = (errors:ErrorResponseRegister) => {
    if(errors.email){
        return errors.email[0]
    }
    if(errors.password){
        return errors.password[0]
    }

    if(errors.document_number){
        return errors.document_number[0]
    }
    return ""
}