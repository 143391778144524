import LogoPozicion from "../../components/Logos/LogoPozicion"
import FormRegister from "./components/FormRegister";
import LinkContainer from "../../containers/Link.container";
import SVGFacebook from "../../components/svg/SVGFacebook";
import SVGGoogle from "../../components/svg/SVGGoogle";
import { useSearchParams } from "react-router-dom";
import { StyledMainLogin } from "../Login/styles";

const Register = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const redirect = searchParams.get("continue")

    return (
        <StyledMainLogin>
            <div className="register-container">
                <LinkContainer href={redirect ? `/login?continue=${encodeURIComponent(redirect)}` : "/login"}>
                    <LogoPozicion width={277} height={50} />
                </LinkContainer>
                <p className="subtitle">
                    Regístrate gratis para continuar leyendo y estar siempre informadocon las noticias más relevantes.
                    Además con tu cuenta podrás seleccionar y comentar las noticias y periodistas que más te interesen.
                    Así como también recibirrecomendaciones en función a tus gustos.
                </p>
                <div className="login-container">
                    <p className="login-title">Regístrate</p>
                    <p className="login-subtitle">Accede fácilmente con:</p>
                    <ul className="login-features">
                        <li className="login-item">
                            <SVGFacebook width={18} height={18} />
                        </li>
                        <li className="login-item">
                            <SVGGoogle width={20} height={20} />
                        </li>
                    </ul>
                    <p className="login-subtitle">o completa tus datos para registrarte</p>
                    <FormRegister />
                    <p className="login-spam">
                        ¿Ya tienes una cuenta?
                        &nbsp;
                        <LinkContainer href={redirect ? `/login?continue=${encodeURIComponent(redirect)}` : "/login"}>
                            <span>Inicia sesion</span>
                        </LinkContainer>
                    </p>
                </div>
            </div>
        </StyledMainLogin>
    )
}

export default Register