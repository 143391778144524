import InputPassword from "../../../components/InputPassword";
import SimpleLoader from "../../../components/Loaders/SimpleLoader";
import { InterfaceLogin } from "../../../interfaces/Credentials";
import { LoginUser } from "../../../services/auth.services";
import { ValidateCredentials } from "../../../utils/Validate";
import React, { useState } from "react";
import Cookies from 'js-cookie'
import { TOKEN_NAME } from "../../../config/index";
import { useSearchParams } from "react-router-dom";

const FormLogin = () => {
    const [searchParams, setSearchParams]  = useSearchParams()
    const redirect = searchParams.get("continue")
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<InterfaceLogin>({
        email: "",
        password: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const sendForm = (e: React.MouseEvent<HTMLButtonElement>) =>{
        e.preventDefault();
        const validate = ValidateCredentials(form);

        if(!validate.error){
            setLoading(true)
            LoginUser(form)
            .then( (data) => {
                if(!data){
                    setMessage("Ocurrio un error")
                    setLoading(false)
                    return
                }
                Cookies.set(TOKEN_NAME, data.token)
                setMessage("") 
                redirect 
                        ? window.location.href = redirect+"?ticket="+data.ticket
                        : window.location.href = "/me"
                
            } )
            .catch( (e) => {
                setMessage(e.message)
                setLoading(false)
            } )
        }else{
            setMessage(validate.message)
        }
    } 

    
    if(loading){
        return(
            <div  className="login-form">
                <SimpleLoader/>
                <p className="login-title">Comprobando credenciales</p>
            </div>
        )
    }

    return(
        <form className="login-form">
            <input 
                name="email"
                onChange={handleChange}
                className="login-input" 
                type="text" 
                placeholder="Email" 
                value={form.email}
            />
            <InputPassword
                name="password"
                handleChange={handleChange}
                style="login-input" 
                placeholder="Contraseña" 
                value={form.password}
            />
            <p className="text-error"> {message} </p>
            <button onClick={sendForm} className="btn-primary login-btn">Ingresar</button>
        </form>
    )
}

export default FormLogin