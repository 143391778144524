import { SERVICE_URL,TOKEN_NAME,SERVICE_AUTH_URL } from "../config";
import axios from "axios";
import Cookies from "js-cookie";
import { InterfaceLogin, IRegister } from "../interfaces/Credentials";

const header = {
    headers:{
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${Cookies.get(TOKEN_NAME)}`,
    }
}

export const getDataUser = () => {
    return axios.post(`${SERVICE_URL}api/auth/me`,{},header);
}

export const LoginOut = () => {
    return axios.post(`${SERVICE_URL}api/auth/logout`,{},header);
}

export const validateToken = async (): Promise< {ticket: string} | null > => {
    const resp = await axios.post(`${SERVICE_AUTH_URL}api/v1/generateTicket`,{},{headers: header.headers}).catch( e => console.error(e) )

    if(resp && resp.status === 200) {
        return resp.data.data
    }

    return null
}

export const RegisterUser = (data: IRegister) => {
    return axios.post(`${SERVICE_URL}api/auth/register`,data);
}

export const LoginUser = async (data: InterfaceLogin): Promise< {ticket: string, token: string} | null > => {
    const resp = await axios.post(`${SERVICE_AUTH_URL}api/v1/login`,data,{headers: header.headers})
        .catch( e => {
            throw new Error(e.response.data.error || e.message)
        } )

    if(resp && resp.status === 200) {
        return resp.data.data
    }

    return null
}